import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useStripe } from "@stripe/react-stripe-js";
import {
  refreshStripeOnboardingUrl,
  addExternalAccount,
  readExternalAccount,
  generateFinancialConnectionToken,
  updatePayoutUser,
} from "../settings/actions/index";
import { requestAuthLink, setLoginUserFromCookie } from "../login/actions";
import ProgressStepper from "../../components/ProgressStepper";
import EmailVerification from "./components/EmailVerificationStep";
import StripeOnboardingStep from "./components/StripeOnboardingStep";
import FinancialAccountStep from "./components/FinancialAccountStep";
import AdditionalInfoStep from "./components/AdditionalInfoStep";
import AlertBanner from "../../components/AlertBanner";

function AccountCompleteSetup({
  loginUser,
  stripeOnboardingUrl,
  externalAccount,
  stripeFinancialToken,
  shouldUpdateUser,
  showConfirmation,

  refreshStripeOnboardingUrl,
  addExternalAccount,
  readExternalAccount,
  generateFinancialConnectionToken,
  updatePayoutUser,
  requestAuthLink,
  setLoginUserFromCookie,

  ...props
}) {
  const startAtStage = checkRequiredUserConfig(loginUser);

  const [showSection, setShowSection] = useState(!!startAtStage);
  const [showConfig, setShowConfig] = useState(!!startAtStage);
  const [nextButtonType, setNextButtonType] = useState("next");
  const [pendingOpenModal, setPendingOpenModal] = useState(false);
  const stripe = useStripe();

  const openModalWithToken = (token) => {
    setPendingOpenModal(false);
    stripe.collectBankAccountToken({ clientSecret: token }).then((result) => {
      if (result?.token?.id) {
        const token = result.token.id;
        if (token) addExternalAccount(token);
      } else {
        // TODO: @error
      }
    });
  };

  const stageDict = {
    verify: 0,
    onboarding: 1,
    externalAccount: 2,
    handle: 3,
    phone: 3,
  };

  const closeConfiguration = () => {
    setShowConfig(false);
    if (!checkRequiredUserConfig(loginUser)) {
      setShowSection(false);
    }
  };

  useEffect(() => {
    refreshStripeOnboardingUrl();
  }, [loginUser.needsStripeOnboarding, refreshStripeOnboardingUrl]);

  useEffect(() => {
    if (stripeFinancialToken && pendingOpenModal) {
      openModalWithToken(stripeFinancialToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeFinancialToken, openModalWithToken]);

  useEffect(() => {
    if (!loginUser.needsStripeFinancialConnection) {
      readExternalAccount();
    }
  }, [loginUser.needsStripeFinancialConnection, readExternalAccount]);

  useEffect(() => {
    if (shouldUpdateUser) {
      setLoginUserFromCookie();
    }
  }, [shouldUpdateUser, setLoginUserFromCookie]);

  const initiateFinancialConnectionsModal = async () => {
    setPendingOpenModal(true);
    await generateFinancialConnectionToken();
  };

  const steps = [
    {
      name: "Email Verification",
      component: (
        <EmailVerification
          loginUser={loginUser}
          requestAuthLink={requestAuthLink}
          setNextButtonType={setNextButtonType}
        />
      ),
    },
    {
      name: "Stripe Onboarding",
      component: (
        <StripeOnboardingStep
          loginUser={loginUser}
          stripeOnboardingUrl={stripeOnboardingUrl}
          setNextButtonType={setNextButtonType}
        />
      ),
    },
    {
      name: "Payout Account Link",
      component: (
        <FinancialAccountStep
          loginUser={loginUser}
          initiateFinancialConnectionsModal={initiateFinancialConnectionsModal}
          setNextButtonType={setNextButtonType}
          externalAccount={externalAccount}
        />
      ),
    },
    // {
    //   name: "Wrapping Up",
    //   component: (
    //     <AdditionalInfoStep
    //       loginUser={loginUser}
    //       setNextButtonType={setNextButtonType}
    //       updatePayoutUser={updatePayoutUser}
    //       showConfirmation={showConfirmation}
    //       closeConfiguration={closeConfiguration}
    //     />
    //   ),
    // },
    {
      name: "",
      component: <div className="review"></div>,
    },
  ];

  const openConfigButton = (
    <Button
      onClick={() => setShowConfig(true)}
      className="btn btn-default btn-warning my-2"
    >
      Open Configuration
    </Button>
  );

  return (
    <>
      {showSection && (
        <>
          {showConfig ? (
            <Row>
              <Col xs="12">
                <h1 className="heading title">Account Configuration</h1>
                <AlertBanner message="Payouts are disabled. Complete all required account configuration steps to enable payouts." />
              </Col>
              <Col xs="12">
                <Card className="card-custom">
                  <CardBody>
                    <ProgressStepper
                      startAtStep={startAtStage ? stageDict[startAtStage] : 0}
                      showNavigation={true}
                      steps={steps}
                      nextButtonText={buttonConfigForType(nextButtonType).text}
                      nextButtonCls={buttonConfigForType(nextButtonType).class}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <h1 className="heading title">Account Configuration</h1>
                <AlertBanner
                  message={
                    "Payouts are disabled. Complete all required account configuration steps to enable payouts."
                  }
                  button={openConfigButton}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}

function buttonConfigForType(nextButtonType = "next") {
  switch (nextButtonType) {
    case "save":
      return {
        text: "Save",
        class: "btn btn-primary float-right btn-stepper stepper-nav-btn",
      };
    case "finish":
      return {
        text: "Finish",
        class: "btn btn-primary float-right btn-stepper stepper-nav-btn",
      };
    case "skip":
      return {
        text: "Skip for now",
        class:
          "btn btn-outline-primary float-right btn-stepper stepper-nav-btn",
      };
    case "next":
    default:
      return {
        text: "Next",
        class: "btn btn-primary float-right btn-stepper stepper-nav-btn",
      };
  }
}

function checkRequiredUserConfig(loginUser) {
  if (!loginUser) return;
  if (!loginUser.emailVerified) return "verify";
  if (loginUser.needsStripeOnboarding) return "onboarding";
  if (loginUser.needsStripeFinancialConnection) return "externalAccount";
  // if (!loginUser.phone) return "phone";
  return false;
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    stripeOnboardingUrl: state.settings.stripeOnboardingUrl,
    externalAccount: state.settings.externalAccount,
    stripeFinancialToken: state.settings.stripeFinancialToken,
    shouldUpdateUser: state.settings.shouldUpdateUser,
    showConfirmation: state.settings.showConfirmation,
  };
};
export default connect(mapStateToProps, {
  refreshStripeOnboardingUrl,
  requestAuthLink,
  addExternalAccount,
  readExternalAccount,
  generateFinancialConnectionToken,
  setLoginUserFromCookie,
  updatePayoutUser,
})(AccountCompleteSetup);
