import { useEffect } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { PlusIcon } from "@heroicons/react/20/solid";

import SocialHandleListItem from "./SocialHandleListItem";
import mediaKitHeroSvg from "../../../assets/img/media_kit_hero.svg";
import { getMediaKits } from "../actions";

const SocialHandlesList = ({
  loginUser,
  getMediaKits,
  mediaKits,
  ...props
}) => {
  useEffect(() => {
    getMediaKits();
  }, [getMediaKits]);

  const sortedHandles = sortHandles(mediaKits);

  return (
    <div className="social-handles-list">
      {Array.isArray(mediaKits) ? (
        <>
          {!!sortedHandles?.length ? (
            <Row>
              <Col xs="12" lg="10" xl="8" className="mb-5">
                <h1 className="heading title">Media Kits</h1>
                <p className="subheading text-grey">
                  Tap on a handle below to manage your sharable Media Kit.
                  Please contact a NeoReach administrator for any issues with
                  your media kit.
                </p>
                {sortedHandles.map((handle) => (
                  <SocialHandleListItem
                    socialHandle={handle}
                    key={handle?._id}
                    actionBtnEnabled={true}
                  />
                ))}
              </Col>
              {/* <Col xs="12">
                <Link
                  className="btn btn-primary my-3 link-as-btn btn-with-icon"
                  role="button"
                  to="/settings"
                >
                  <PlusIcon width={18} height={18} className="mr-2" />
                  Add/Remove Handles in Settings
                </Link>
              </Col> */}
            </Row>
          ) : (
            <Row className="px-3 py-5">
              <Col xs="8" md="8" lg="6" xl="5" className="mx-auto mb-4">
                <img
                  src={mediaKitHeroSvg}
                  alt="Media Kit Hero banner. Person presenting social media data."
                  className="img-fluid"
                />
              </Col>
              <Col
                xs="12"
                md="10"
                lg="8"
                className="offset-md-1 offset-lg-2  text-center"
              >
                <h1 className="heading title">Media Kits</h1>
                <p className="subheading text-grey">
                  Welcome to NeoReach Media Kits! View and share stats about
                  your social media presence on TikTok, YouTube, and Instagram -
                  all automated. Enter your handles in Settings to view your
                  publicly shareable Media Kit.
                </p>
                {/* TODO: fix vertically off-center buttons */}
                <Link
                  className="btn btn-primary my-3 link-as-btn btn-with-icon"
                  role="button"
                  to="/settings"
                >
                  <PlusIcon width={18} height={18} className="mr-2" />
                  Add Handle in Settings
                </Link>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Row>
          <Col xs="12" lg="10" xl="8">
            <h1 className="heading title">
              <Skeleton height={43} width="40%" />
            </h1>
            <p className="subheading">
              <Skeleton height={24} width="25%" />
            </p>
            <Skeleton
              height={155}
              count={3}
              className="handle-list-item-skeleton"
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

function sortHandles(mediaKits) {
  if (!mediaKits) return [];
  const sortedMediaKits = [...mediaKits];
  sortedMediaKits.sort((a, b) => {
    if (a?.hasConfirmedOwnership && !a?.notFound) {
      return -1;
    } else if (a?.notFound) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedMediaKits;
}

const mapStateToProps = (state) => {
  return {
    loading: state.mediaKits.loading,
    loginUser: state.login.loginUser,
    mediaKits: state.mediaKits.mediaKits,
  };
};
export default connect(mapStateToProps, {
  getMediaKits,
})(SocialHandlesList);
