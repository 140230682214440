import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import _ from "lodash";
import { Field, Formik, Form, FieldArray } from "formik";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  updatePayoutUser,
  clearErrorMessages,
  refreshStripeOnboardingUrl,
  clearSuccessMessages,
  handleDeleteAccount,
} from "./actions/index";
import { showErrorMessage, showSuccessMessage } from "../../actions";
import {
  RenderButton,
  RenderCheckBox,
  RenderLabel,
  RenderPasswordField,
  RenderSingleSelect,
  SubmitButton,
} from "../../components";
import { setLoginUserFromCookie } from "../login/actions";
import FinancialAccountSection from "./components/FinancialAccountSection/index.js";
import StripeOnboardingSection from "./components/StripeOnboardingSection";
import { TrashIcon } from "@heroicons/react/24/outline";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ContactDetailsSkeleton from "./components/ContactDetailsSkeleton";

function SettingsComponent({
  loginUser,
  updatePayoutUser,
  stripeOnboardingUrl,
  refreshStripeOnboardingUrl,
  errorMessage,
  successMessage,
  clearErrorMessages,
  clearSuccessMessages,
  setLoginUserFromCookie,
  shouldUpdateUser,
  handleDeleteAccount,
  ...props
}) {
  const isLoading = useSelector((state) => state.settings.loading);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  const toggleDeleteAccountModal = () => {
    setDeleteAccountModalOpen(!deleteAccountModalOpen);
  };

  useEffect(() => {
    refreshStripeOnboardingUrl();
  }, [loginUser.needsStripeOnboarding, refreshStripeOnboardingUrl]);

  useEffect(() => {
    if (deleteAccountModalOpen) {
      setLoginUserFromCookie();
    }
  }, [deleteAccountModalOpen, setLoginUserFromCookie]);

  useEffect(() => {
    if (errorMessage) {
      showErrorMessage(errorMessage, () => {
        clearErrorMessages();
      });
    }
  }, [errorMessage, clearErrorMessages]);

  useEffect(() => {
    if (successMessage) {
      showSuccessMessage(successMessage, () => {
        clearSuccessMessages();
      });
    }
  }, [successMessage, clearSuccessMessages]);

  useEffect(() => {
    if (shouldUpdateUser) {
      setLoginUserFromCookie();
    }
  }, [shouldUpdateUser, setLoginUserFromCookie]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateUser = (values) => {
    const errors = {};

    if (values?.phone && !/^[+()\d\-\s.ext]{9,}$/im.test(values?.phone)) {
      errors.phone = "Invalid phone number";
    }

    if (values?.socialHandles?.length) {
      const socialHandleErrors = validateArrayField(values?.socialHandles);
      if (socialHandleErrors) {
        errors.socialHandles = socialHandleErrors;
      }
    }

    if (values?.newPassword) {
      if (!values?.oldPassword || values?.oldPassword?.trim() === "") {
        errors.oldPassword = "Must enter current password";
      }
      if (values?.newPassword?.trim().length < 8) {
        errors.newPassword = "Must have at least 8 characters";
      }
      if (
        !values?.newPasswordConfirm ||
        values?.newPasswordConfirm?.trim() === ""
      ) {
        errors.newPasswordConfirm = "Must confirm new password";
      }
      if (values?.newPassword?.trim() !== values?.newPasswordConfirm?.trim()) {
        errors.newPasswordConfirm = "Password doesn't match";
      }
    }
    return errors;
  };

  function validateArrayField(fieldValues) {
    const errors = {};
    for (let i = 0; i < fieldValues.length; i++) {
      const arrayItemErrors = {};
      const arrayItem = fieldValues[i];

      if (!arrayItem.handle?.trim()) {
        arrayItemErrors.handle = "Required";
      }
      if (!arrayItem.network?.value?.trim()) {
        arrayItemErrors.network = "Required";
      }
      if (!arrayItem.rate?.trim()) {
        arrayItemErrors.rate = "Required";
      }

      if (Object.keys(arrayItemErrors).length > 0) {
        errors[i] = arrayItemErrors;
      }
    }
    if (Object.keys(errors).length > 0) {
      return errors;
    }
  }

  const networkOptions = [
    { label: "Instagram", value: "instagram" },
    { label: "TikTok", value: "tiktok" },
    { label: "YouTube", value: "youtube" },
  ];

  const formatSocialHandle = (socialHandle) => {
    return {
      ...socialHandle,
      network: networkOptions.find(
        (option) => option.value === socialHandle.network,
      ),
    };
  };

  const initialUserValues = {
    name: loginUser?.name,
    email: loginUser?.email,
    phone: loginUser?.phone,
    enableNotifications: loginUser?.enableNotifications,
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    socialHandles: loginUser?.socialHandles
      ? loginUser.socialHandles.map((handle) => formatSocialHandle(handle))
      : [],
  };

  return (
    <>
      <div className="container">
        <h1 className="heading title">
          {isLoading ? <Skeleton width={"30%"} /> : "Settings"}
        </h1>
        <Row>
          <Col xs="12" md="10" lg="8">
            <Card className="card-custom">
              <CardBody>
                <StripeOnboardingSection
                  loginUser={loginUser}
                  stripeOnboardingUrl={stripeOnboardingUrl}
                />
              </CardBody>
            </Card>
            <Card className="card-custom">
              <CardBody>
                <FinancialAccountSection />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="10" lg="8">
            <Card className="card-custom">
              <CardBody>
                {isLoading ? (
                  <ContactDetailsSkeleton />
                ) : (
                  <Formik
                    initialValues={initialUserValues}
                    enableReinitialize={true}
                    // onSubmit={handleUserSubmit}
                    onSubmit={async (values, { setSubmitting }) => {
                      const updatedFields = {};
                      for (const field in initialUserValues) {
                        if (
                          !_.isEqual(initialUserValues[field], values[field])
                        ) {
                          updatedFields[field] = values[field];
                        }
                      }
                      if (updatedFields.socialHandles) {
                        updatedFields.socialHandles =
                          updatedFields.socialHandles.map((handle) => {
                            return {
                              ...handle,
                              network: handle?.network?.value,
                            };
                          });
                      }
                      updatePayoutUser(updatedFields);
                      setSubmitting(false);
                    }}
                    validate={validateUser}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      setFieldError,
                      ...props
                    }) => (
                      <Form
                        onSubmit={props.handleSubmit}
                        className="form-group"
                      >
                        <h2 className="subheading title">Contact Details</h2>
                        <Row>
                          <Col xs="12">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <Field
                              id="name"
                              name="name"
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="6">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <Field
                              id="email"
                              name="email"
                              type="email"
                              className="form-control disabled"
                              disabled={true}
                            />
                          </Col>
                          <Col xs="12" md="6">
                            <label htmlFor="phone" className="form-label">
                              Phone Number
                            </label>
                            <Field
                              id="phone"
                              name="phone"
                              type="phone"
                              className="form-control"
                            />
                          </Col>
                        </Row>

                        <div className="subheading mt-5">
                          <h2 className="title">Social Handles and Rates</h2>
                        </div>
                        <FieldArray name="socialHandles">
                          {({ insert, remove, push }) => (
                            <div className="social-handles-container">
                              {values.socialHandles?.map((handle, index) => (
                                <div key={index} className="social-handle">
                                  <Row>
                                    <Col xs="12" lg="4">
                                      <label
                                        htmlFor={`socialHandles.${index}.handle`}
                                        className="form-label"
                                      >
                                        Handle
                                        {errors?.socialHandles?.[index]
                                          ?.handle &&
                                          touched?.socialHandles?.[index]
                                            ?.handle && (
                                            <span className="required ml-2">
                                              *
                                              {errors?.socialHandles?.[index]
                                                ?.handle || "Required"}
                                            </span>
                                          )}
                                      </label>
                                      <Field
                                        id={`socialHandles.${index}.handle`}
                                        name={`socialHandles.${index}.handle`}
                                        type="text"
                                        className="form-control"
                                        autoComplete="off"
                                        disabled={true}
                                      />
                                    </Col>
                                    <Col xs="12" lg="3">
                                      <label
                                        htmlFor={`socialHandles.${index}.network`}
                                        className="form-label"
                                      >
                                        Network
                                        {errors?.socialHandles?.[index]
                                          ?.network &&
                                          touched?.socialHandles?.[index]
                                            ?.network && (
                                            <span className="required ml-2">
                                              *
                                              {errors?.socialHandles?.[index]
                                                ?.network || "Required"}
                                            </span>
                                          )}
                                      </label>
                                      <Field
                                        as="select"
                                        name="handleNetwork"
                                        className={
                                          errors?.handleNetwork &&
                                          touched?.handleNetwork
                                            ? "error"
                                            : ""
                                        }
                                        onChange={(input) => {
                                          setFieldValue(
                                            `socialHandles.${index}.network`,
                                            input,
                                          );
                                        }}
                                        value={handle.network}
                                        options={networkOptions}
                                        component={RenderSingleSelect}
                                      />
                                    </Col>
                                    <Col xs="9" lg="4">
                                      <label
                                        htmlFor={`socialHandles.${index}.rate`}
                                        className="form-label"
                                      >
                                        Est. Price/Post
                                        {errors?.socialHandles?.[index]?.rate &&
                                          touched?.socialHandles?.[index]
                                            ?.rate && (
                                            <span className="required ml-2">
                                              *
                                              {errors?.socialHandles?.[index]
                                                ?.rate || "Required"}
                                            </span>
                                          )}
                                      </label>
                                      <Field
                                        id={`socialHandles.${index}.rate`}
                                        name={`socialHandles.${index}.rate`}
                                        type="text"
                                        className="form-control"
                                        autoComplete="off"
                                      />
                                    </Col>
                                    <Col xs="3" lg="1" className="pl-lg-0">
                                      <RenderButton
                                        type="button"
                                        className="btn btn-outline-primary btn-inline-form-field"
                                        onClick={() => {
                                          // Clear array-like errors before calling remove to workaround a Formik bug that crashes the page. Real fix is in Formik v2
                                          // https://github.com/jaredpalmer/formik/issues/1158
                                          setFieldError("socialHandles", []);
                                          remove(index);
                                        }}
                                      >
                                        <TrashIcon
                                          width={20}
                                          height={20}
                                          strokeWidth={2}
                                        ></TrashIcon>
                                      </RenderButton>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                              {/* {values.socialHandles?.length < 10 && (
                                <RenderButton
                                  type="button"
                                  className="btn btn-outline-primary mt-2"
                                  onClick={() =>
                                    push({
                                      handle: "",
                                      network: {},
                                      rate: "",
                                    })
                                  }
                                >
                                  Add a Handle
                                </RenderButton>
                              )} */}
                            </div>
                          )}
                        </FieldArray>

                        <div className="subheading mt-5">
                          <h2 className="title">Notification Settings</h2>
                        </div>

                        <div
                          style={{
                            marginLeft: "1.25rem",
                          }}
                        >
                          <Field
                            name="enableNotifications"
                            id="enableNotifications"
                            type="checkbox"
                            onChange={(e) =>
                              setFieldValue(
                                "enableNotifications",
                                e.target.checked,
                              )
                            }
                            checked={values.enableNotifications}
                            component={RenderCheckBox}
                            className="styled-checkbox"
                          />
                          <RenderLabel check for="enableNotifications">
                            <p className="text-grey">Enable notifications</p>
                          </RenderLabel>
                        </div>

                        <h2 className="subheading title mt-5">
                          Change Password
                        </h2>
                        <label htmlFor="oldPassword" className="form-label">
                          Current Password
                        </label>
                        <Field
                          id="oldPassword"
                          name="oldPassword"
                          type="password"
                          className="form-control"
                          component={RenderPasswordField}
                        />

                        <label htmlFor="newPassword" className="form-label">
                          New Password
                        </label>
                        <Field
                          id="newPassword"
                          name="newPassword"
                          type="password"
                          className="form-control"
                          autoComplete="new-password"
                          component={RenderPasswordField}
                        />

                        <label
                          htmlFor="newPasswordConfirm"
                          className="form-label"
                        >
                          Confirm New Password
                        </label>
                        <Field
                          id="newPasswordConfirm"
                          name="newPasswordConfirm"
                          type="password"
                          className="form-control"
                          autoComplete="new-password"
                          component={RenderPasswordField}
                        />

                        <SubmitButton
                          disabled={props.isSubmitting}
                          className="mt-5 btn btn-default btn-primary"
                        >
                          Save Changes
                        </SubmitButton>
                      </Form>
                    )}
                  </Formik>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="10" lg="8">
            <Card className="card-custom">
              <CardBody>
                <h2 className="subheading title">Danger Zone</h2>
                <RenderButton
                  className="btn btn-default btn-danger"
                  onClick={() => {
                    setDeleteAccountModalOpen(true);
                  }}
                >
                  Delete Account
                </RenderButton>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={deleteAccountModalOpen}
        toggle={toggleDeleteAccountModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleDeleteAccountModal}>
          Delete Account
        </ModalHeader>
        <ModalBody>
          {loginUser.__canDeleteUser ? (
            <>
              <p>
                Are you sure you want to delete your account? This action cannot
                be undone.
              </p>
              <RenderButton
                className="btn btn-default btn-danger"
                onClick={() => {
                  handleDeleteAccount(loginUser._id);
                }}
              >
                Confirm Delete Account
              </RenderButton>
            </>
          ) : (
            <>
              <p>
                {loginUser.__canDeleteUserMessage
                  ? `Your account is unable to be deleted automatically at this time - ${loginUser.__canDeleteUserMessage}. Please contact support for assistance deleting your account.`
                  : "Your account is unable to be deleted automatically at this time. Please contact support for assistance deleting your account."}
              </p>
              <RenderButton
                className="btn btn-outline-primary"
                onClick={() => {
                  const to = "team@neoreach.com";
                  const subject = "Delete Account Request";
                  const body = `Please delete my account with email address ${loginUser.email}`;
                  const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(
                    subject,
                  )}&body=${encodeURIComponent(body)}`;
                  window.open(mailtoLink);
                }}
              >
                Contact Support
              </RenderButton>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    errorMessage: state.settings.errorMessage,
    successMessage: state.settings.successMessage,
    stripeOnboardingUrl: state.settings.stripeOnboardingUrl,
    shouldUpdateUser: state.settings.shouldUpdateUser,
  };
};
export default connect(mapStateToProps, {
  updatePayoutUser,
  refreshStripeOnboardingUrl,
  clearErrorMessages,
  clearSuccessMessages,
  setLoginUserFromCookie,
  handleDeleteAccount,
})(SettingsComponent);
