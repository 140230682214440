import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Row,
  Spinner,
} from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
import {
  RenderCurrency,
  RenderPasswordField,
  RenderSingleSelect,
  RenderTextField,
  SubmitButton,
} from "../../components";
import {
  clearAccountErrors,
  getCampaignDisplayInfo,
  register,
} from "./actions/index";
import { requestAuthLink } from "../login/actions";
import AlertBanner from "../../components/AlertBanner";
import PublicCardTitleSection from "../../components/PublicCardTitleSection";
import "./index.scss";

const Signup = ({
  loading,
  signupFailed,
  duplicateAccountError,
  duplicateAccountEmail,
  campaignName,

  register,
  getCampaignDisplayInfo,
  clearAccountErrors,
}) => {
  useEffect(() => {
    clearAccountErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = useSelector((state) => state.signup.loading);

  const [country, setCountry] = useState({
    label: "United States",
    value: "US",
  });
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const campaignIdParam = new URLSearchParams(search).get("campaign_id");

  const [campaignId, setCampaignId] = useState(campaignIdParam);
  const [rate, setRate] = useState(null);

  useEffect(() => {
    if (token?.split(".")?.length === 2) {
      // validate if first part of token is valid MongoDB object id
      const regex = /^[0-9a-fA-F]{24}$/;
      if (regex.test(token.split(".")[0])) {
        setCampaignId(token.split(".")[0]);
        if (token.split(".")[1]) {
          setRate(token.split(".")[1]);
        }
      }
    } else {
      setCampaignId(token);
    }
  }, [token]);

  useEffect(() => {
    if (campaignId) {
      getCampaignDisplayInfo(campaignId);
    }
  }, [campaignId, getCampaignDisplayInfo]);

  const countryOptions = [
    { label: "United States", value: "US" },
    { label: "United Kingdom", value: "GB" },
    { label: "Canada", value: "CA" },
    { label: "Australia", value: "AU" },
    { label: "Mexico", value: "MX" },
    { label: "France", value: "FR" },
    { label: "Hong Kong", value: "HK" },
    { label: "Germany", value: "DE" },
    { label: "Italy", value: "IT" },
    { label: "Israel", value: "IL" },
    { label: "Spain", value: "ES" },
    { label: "Netherlands", value: "NL" },
    { label: "New Zealand", value: "NZ" },
    { label: "Belgium", value: "BE" },
    { label: "Austria", value: "AT" },
    { label: "Finland", value: "FI" },
    { label: "Greece", value: "GR" },
    { label: "Ireland", value: "IE" },
    { label: "Luxembourg", value: "LU" },
    { label: "Portugal", value: "PT" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
  ].sort((a, b) => (a.label > b.label ? 1 : -1));

  const handleCountrySelectChange = (value) => {
    setCountry(value);
  };

  const validate = (values) => {
    const errors = {};

    if (!values?.email || values?.email.trim() === "") {
      errors.email = "This field is required";
    } else if (
      values?.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(values?.email)
    ) {
      errors.email = "Invalid email";
    }

    if (!values?.password || values?.password.trim() === "") {
      errors.password = "This field is required";
    } else if (values?.password.trim().length < 8) {
      errors.password = "Must be at least 8 characters";
    }

    if (!values?.password2 || values?.password2.trim() === "") {
      errors.password2 = "This field is required";
    } else if (
      values?.password &&
      values?.password2 &&
      values?.password.trim() !== values?.password2.trim()
    ) {
      errors.password2 = "Password confirmation doesn't match";
    }

    return errors;
  };

  const magicLoginLink = () => {
    return (
      <NavLink
        to={`/magicLink?email=${encodeURIComponent(duplicateAccountEmail)}`}
        className="link-more"
      >
        login
      </NavLink>
    );
  };

  const RecoveryMessage = () => {
    return (
      <>
        An account is already registered to this email. To access your account
        please {magicLoginLink()}.
      </>
    );
  };

  return (
    <>
      <div className="container public-page-container signup-container">
        <Row>
          <Col xs="12" md="8" lg="6">
            <Card className="card-custom px-md-4 px-s-1">
              <CardBody>
                <PublicCardTitleSection
                  title="Create an account"
                  subtitle={"Welcome to the Creator Portal"}
                />
                <Row>
                  <Col>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                        password2: "",
                        country: "US",
                      }}
                      initialErrors={{
                        email: "This field is required",
                        password: "This field is required",
                        password2: "This field is required",
                        country: "This field is required",
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        register({
                          ...values,
                          country: country.value,
                          token,
                          campaign_id: campaignIdParam,
                        });
                        setSubmitting(false);
                      }}
                      validate={validate}
                      validateOnBlur={true}
                      validateOnMount={true}
                      isInitialValid
                    >
                      {({
                        touched,
                        errors,
                        validateForm,
                        setTouched,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        ...props
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>
                            <>
                              {campaignName && (
                                <Alert color="primary" className="text-center">
                                  <h4 className="mb-0">
                                    You&apos;ve been invited to the campaign{" "}
                                    <b>{campaignName}</b>
                                    {!!rate &&
                                      !isNaN(rate) &&
                                      Number(rate) > 0 && (
                                        <>
                                          {" "}
                                          with a starting balance of{" "}
                                          <b>
                                            <RenderCurrency amount={rate} />
                                          </b>
                                        </>
                                      )}
                                  </h4>
                                </Alert>
                              )}
                              <Row>
                                <Col>
                                  <label htmlFor="email" className="form-label">
                                    Email
                                    {errors?.email && touched?.email && (
                                      <span className="required ml-2">
                                        *
                                        {errors?.email ||
                                          "A valid email is required"}
                                      </span>
                                    )}
                                  </label>
                                  <Field
                                    // autoFocus
                                    name="email"
                                    className={
                                      errors?.email && touched?.email
                                        ? "error"
                                        : ""
                                    }
                                    component={RenderTextField}
                                    autoComplete="email"
                                  />

                                  <label
                                    htmlFor="password"
                                    className="form-label mt-3"
                                  >
                                    Password
                                    {errors?.password && touched?.password && (
                                      <span className="required ml-2">
                                        *
                                        {errors?.password ||
                                          "A valid password is required"}
                                      </span>
                                    )}
                                  </label>
                                  <Field
                                    name="password"
                                    className={
                                      errors?.password && touched?.password
                                        ? "error"
                                        : ""
                                    }
                                    component={RenderPasswordField}
                                    autoComplete="new-password"
                                  />

                                  <label
                                    htmlFor="password2"
                                    className="form-label mt-3"
                                  >
                                    Confirm Password
                                    {errors?.password2 &&
                                      touched?.password2 && (
                                        <span className="required ml-2">
                                          *
                                          {errors?.password2 ||
                                            "Passwords must match"}
                                        </span>
                                      )}
                                  </label>
                                  <Field
                                    name="password2"
                                    className={
                                      errors?.password2 && touched?.password2
                                        ? "error"
                                        : ""
                                    }
                                    component={RenderPasswordField}
                                    autoComplete="new-password"
                                  />

                                  <label
                                    htmlFor="country"
                                    className="form-label mt-3"
                                  >
                                    Select Country
                                    {errors?.country && touched?.country && (
                                      <span className="required ml-2">
                                        *
                                        {errors?.country ||
                                          "Country is required"}
                                      </span>
                                    )}
                                  </label>
                                  <Field
                                    name="country"
                                    className={
                                      errors?.country && touched?.country
                                        ? "error"
                                        : ""
                                    }
                                    value={country}
                                    onChange={(input) => {
                                      handleCountrySelectChange(input);
                                    }}
                                    onBlur={handleBlur}
                                    options={countryOptions}
                                    component={RenderSingleSelect}
                                  />
                                </Col>
                              </Row>
                              {signupFailed && (
                                <>
                                  {duplicateAccountError &&
                                  duplicateAccountEmail ? (
                                    <Row>
                                      <Col className="mb-0">
                                        <AlertBanner
                                          message={RecoveryMessage()}
                                        />
                                      </Col>
                                    </Row>
                                  ) : (
                                    <AlertBanner
                                      alertType="danger"
                                      message="There was an error creating your account. Please try again."
                                    />
                                  )}
                                </>
                              )}
                              <Row>
                                <Col className="text-center">
                                  <SubmitButton
                                    className="btn btn-primary btn-wide mt-4 mb-1"
                                    disabled={props.isSubmitting || isLoading}
                                  >
                                    {isLoading ? (
                                      <Spinner size="sm" />
                                    ) : (
                                      "Create Account"
                                    )}
                                  </SubmitButton>
                                  <p className="text-grey mt-1 small">
                                    By signing up you agree to our{" "}
                                    <a
                                      className="link-more"
                                      href="https://neoreach.com/influencer-terms-and-condition/"
                                    >
                                      Terms and Conditions
                                    </a>
                                  </p>
                                  <p className="text-grey mt-4 mb-0">
                                    Already have an account?
                                    <NavLink to="/" className="link-more ml-1">
                                      Login
                                    </NavLink>
                                  </p>
                                </Col>
                              </Row>
                            </>
                          </FormGroup>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.signup.loading,
    signupFailed: state.signup.signupFailed,
    duplicateAccountError: state.signup.duplicateAccountError,
    duplicateAccountEmail: state.signup.duplicateAccountEmail,
    campaignName: state.signup.campaignName,
  };
};

export default connect(mapStateToProps, {
  register,
  requestAuthLink,
  clearAccountErrors,
  getCampaignDisplayInfo,
})(Signup);
